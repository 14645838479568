import { greenRing, redRing, yellowRing } from '@app/assets/images';

export const convertProbablityIntoPercentage = (prob: number) => {
  const percentage = prob * 100;
  return parseFloat(percentage?.toFixed(2));
};

export const getRingImage = (prob: number) => {
  const percentage = convertProbablityIntoPercentage(prob);

  if (percentage > 75) {
    return redRing;
  } else if (percentage > 50) {
    return yellowRing;
  } else {
    return greenRing;
  }
};
