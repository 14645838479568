import dayjs from 'dayjs';

export const formatDateTime = (dateTime: string) => {
  return dayjs(dateTime).format('hh:mm a MMMM DD, YYYY');
};

export const utcToDateformate = (date: string) => {
  const parsedDate = dayjs(date);
  const formattedTimestamp = parsedDate.format('hh:mm a MMMM DD, YYYY');
  return formattedTimestamp;
};
