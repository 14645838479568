import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGS } from '@app/constants';
import en from './en.json';
import rs from './rus.json';

const resources = {
  [LANGS.ENGLISH]: {
    translation: en,
  },
  [LANGS.RUSSIAN]: {
    translation: rs,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LANGS.ENGLISH,
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    compatibilityJSON: 'v3',
  });

export default i18n;
