export enum AppErrorCode {
  MEASUREMENT_CODE_FACE_UNDETECTED_ERROR = 80001,
}

export enum InfoType {
  NONE,
  INSTRUCTION,
  SUCCESS,
}

export interface InfoData {
  type: InfoType;
  message?: string;
}

export enum VideoReadyState {
  HAVE_ENOUGH_DATA = 4,
}

export enum BinnahTypes {
  HEART_RATE = 'heartRate',
  PRQ = 'prq',
  BREATHING_RATE = 'breathingRate',
  BLOOD_PRESSURE = 'bloodPressure',
  HEMOGLOBIN = 'hemoglobin',
}

export interface BinnahProps {
  data: {
    title: string;
    value: {
      value: any;
    };
  };
  setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
